
////// scroll
var flag = true;

function headerScroll() {
  var header = document.querySelector('.header');
  if (!header) {
    return;
  }

  var pageYPos;
  var scrollDirection;

  window.addEventListener('scroll', function () {
    scrollDirection = ((window.pageYOffset - pageYPos) > 0) ? 'down' : 'up';
    pageYPos = window.pageYOffset;

    if (flag)
      setTimeout(headerScroll(pageYPos, scrollDirection), 100);

  });

  function headerScroll(pageYPos2, scrollDirection2) {
    if ((scrollDirection2 === 'up' && pageYPos2 <= 100) || (scrollDirection2 === 'up' && pageYPos2 > 100)) {
      header.classList.remove('header--scroll');
      //console.log('remove')
    } else if (scrollDirection2 === 'down' && pageYPos2 > 100) {
      header.classList.add('header--scroll');
      //console.log('add')
    }
  }
}

export {headerScroll};

