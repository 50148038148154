const openAllNews = function () {

  const newsToggle = document.querySelector('.news__toggle');

  if (!newsToggle) {
    return;
  }

  const newsList = document.querySelector('.news__list-other');

  newsToggle.addEventListener('click', function () {
    newsList.classList.add('news__list-other--active');
    newsToggle.classList.add('news__toggle--active');
  });
};

export {openAllNews};
