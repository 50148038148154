import {ieFix} from './utils/ie-fix';
import {iosVhFix} from './utils/ios-vh-fix';

import {openBurger} from './modules/menu-burger';
import {openAllNews} from './modules/open-all-news';
import {initMap} from './modules/init-map';

import {headerScroll} from './modules/header-scroll'

import {initModals} from './modules/init-modals';

// Utils
// ---------------------------------

ieFix();
iosVhFix();

// Modules
// ---------------------------------
headerScroll();
openBurger();
openAllNews();
initMap();
initModals();
