const initMap = () => {
  const mapContainer = document.querySelector('.map');

  if (!mapContainer) {
    return;
  }

  const branches = [
    [59.960201, 30.331822]
  ];

  ymaps.ready(function () {
    const map = new ymaps.Map(mapContainer, {
      center: [59.960201, 30.331822],
      zoom: 14,
      controls: [],
    });

    map.behaviors.disable('scrollZoom');

    let gCollection = new ymaps.GeoObjectCollection();

    branches.forEach((branch) => {
      gCollection.add(new ymaps.Placemark(branch, {}, {
        iconLayout: 'default#image',
        iconImageHref: 'img/content/logo.png',
        iconImageSize: [22, 26],
        iconImageOffset: [-3, -42],
      }));
    });

    map.geoObjects.add(gCollection);

    if (window.matchMedia('(max-width: 768px)').matches) {
      map.behaviors.disable('drag');
    }
  });
};

export {initMap};
